import * as React from 'react'
import styled from 'styled-components'
import { graphql, StaticQuery, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import whoWeAreImage from '../assets/who-we-are.png'
import * as S from '../styles'

const Description = styled(S.Text)`
  color: white;
  margin-bottom: 32px;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`

const MobileCTA = styled(Link)`
  display: none;
  width: 100%;
  margin-top: 30px;

  ${S.Button} {
    width: 100%;
  }

  @media (max-width: 767px) {
    display: inline-block;
  }
`

const DesktopCTA = styled(Link)`
  display: inline-block;

  @media (max-width: 767px) {
    display: none;
  }
`

const copyQuery = graphql`
  {
    allContentfulLayoutCopy(filter: { slug: { eq: "home/story" } }) {
      edges {
        node {
          slug
          headline
          subheadline
          copy {
            copy
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`

const Story: React.SFC<{}> = () => {
  return (
    <StaticQuery
      query={copyQuery}
      render={data => {
        const {
          headline,
          subheadline,
          copy,
          image,
        } = data.allContentfulLayoutCopy.edges[0].node

        return (
          <S.Section className="main  who-we-are">
            <S.Wrapper style={{ position: 'relative', zIndex: 1 }}>
              <S.Grid>
                <S.GridItem
                  width="48%"
                  breakpoints={{
                    767: '100%',
                  }}>
                  <S.Heading as="h3" className="small">
                    {headline}
                  </S.Heading>
                  <S.Heading
                    as="h2"
                    className="large"
                    style={{ marginBottom: 15 }}>
                    {subheadline}
                  </S.Heading>
                  <Description>
                    <ReactMarkdown source={copy.copy} />
                  </Description>
                  <DesktopCTA to="/story">
                    <S.Button className="light">Our Story</S.Button>
                  </DesktopCTA>
                </S.GridItem>
                <S.GridItem
                  width="48%"
                  breakpoints={{
                    767: '100%',
                  }}>
                  <img src={image.file && image.file.url} alt="Who We Are" />
                </S.GridItem>
              </S.Grid>
              <MobileCTA to="/story">
                <S.Button className="light">Our Story</S.Button>
              </MobileCTA>
            </S.Wrapper>
          </S.Section>
        )
      }}
    />
  )
}

export default Story
