import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import * as S from '../styles'

import WinesFeatured from './WinesFeatured'

const query = graphql`
  {
    allContentfulLayoutCopy(filter: { slug: { eq: "home/wines" } }) {
      edges {
        node {
          slug
          headline
          subheadline
          copy {
            copy
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={query}
    render={({ allContentfulLayoutCopy }) => {
      const { headline, subheadline } = allContentfulLayoutCopy.edges[0].node

      return (
        <S.Section className="sub  center">
          <S.Wrapper className="small">
            <div>
              <S.Heading as="h3" className="small" style={{ marginBottom: 15 }}>
                {headline}
              </S.Heading>
              <S.Heading
                as="h2"
                className="medium"
                style={{ marginBottom: '1em' }}>
                {subheadline}
              </S.Heading>
            </div>
            <WinesFeatured />
          </S.Wrapper>
        </S.Section>
      )
    }}
  />
)
