import * as React from 'react'
import { Link } from 'gatsby'
import * as S from '../styles'
import AboutPhilosophy from './AboutPhilosophy'
import AboutTastingGrid from './AboutTastingGrid'

export default () => (
  <S.Section className="sub">
    <S.Wrapper>
      <S.Grid align="center">
        <S.GridItem
          width="46%"
          breakpoints={{
            767: '100%',
          }}>
          <AboutPhilosophy />
        </S.GridItem>
        <S.GridItem
          width="46%"
          breakpoints={{
            767: '100%',
          }}>
          <AboutTastingGrid />
        </S.GridItem>
      </S.Grid>
      <Link to="/shop">
        <S.Button>Shop our wines</S.Button>
      </Link>
    </S.Wrapper>
  </S.Section>
)
