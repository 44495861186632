import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import * as ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import * as S from '../styles'

const Image = styled.img`
  margin-bottom: 3em;
  margin-left: -30px;
  max-width: calc(100% + 30px);
`

const SubSection = styled.div`
  display: flex;
  flex-direction: column;

  ${S.Text} {
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    ${S.Heading} {
      order: 1;
    }
    ${S.Text} {
      order: 2;
    }
    ${Image} {
      order: 3;
    }
  }
`

export const query = graphql`
  {
    allContentfulLayoutCopy(
      filter: { slug: { eq: "home/about/tasting-grid" } }
    ) {
      edges {
        node {
          slug
          headline
          subheadline
          copy {
            copy
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={query}
    render={data => {
      const {
        headline,
        subheadline,
        copy,
        image,
      } = data.allContentfulLayoutCopy.edges[0].node

      return (
        <SubSection>
          <S.Heading as="h2" className="small">
            {headline}
          </S.Heading>
          <S.Heading as="h3" className="medium">
            {subheadline}
          </S.Heading>
          <S.Text>
            <ReactMarkdown source={copy.copy} />
          </S.Text>
          <Image src={image.file.url} />
        </SubSection>
      )
    }}
  />
)
