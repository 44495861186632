import { graphql, Link, StaticQuery } from 'gatsby'
import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import * as S from '../styles'

export const query = graphql`
  {
    allContentfulLayoutCopy(filter: { slug: { eq: "home/intro" } }) {
      edges {
        node {
          slug
          headline
          subheadline
          copy {
            copy
          }
        }
      }
    }
  }
`

const Intro: React.SFC<{}> = () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          headline,
          subheadline,
          copy,
        } = data.allContentfulLayoutCopy.edges[0].node

        return (
          <>
            <S.Section className="intro  home-intro">
              <S.Wrapper
                className="small"
                style={{ position: 'relative', zIndex: 1 }}>
                <div style={{ maxWidth: 639 }}>
                  <S.Heading as="h1" className="small">
                    {headline}
                  </S.Heading>
                  <S.Heading
                    as="h2"
                    className="large"
                    style={{ marginBottom: 15 }}>
                    {subheadline}
                  </S.Heading>
                  <S.Text style={{ color: 'white', marginBottom: 32 }}>
                    <ReactMarkdown source={copy.copy} />
                  </S.Text>
                  <Link to="/shop">
                    <S.Button className="light">Shop our wines</S.Button>
                  </Link>
                </div>
              </S.Wrapper>
            </S.Section>
          </>
        )
      }}
    />
  )
}

export default Intro
