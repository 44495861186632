import * as React from 'react'
import styled from 'styled-components'
import { default as AG } from 'agegate'
import Cookies from 'js-cookie'
import * as S from '../styles'
import logo from '../assets/logo-white.svg'
import bg from '../assets/intro-bg.jpg'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  // background-color: #435f5a;
  background: url(${bg}) center / cover no-repeat;
`

const Inner = styled.div`
  position: absolute;
  width: 100%;
  max-width: 420px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 200px;
    margin: 0 auto 30px;
    display: block;
  }
`

const Form = styled.div`
  background: white;
  padding: 15px;
  border-radius: 4px;

  @media (min-width: 480px) {
    padding: 30px;
  }
`

const Submit = styled(S.Button)`
  width: 100%;
  margin-top: 10px;
`

const ErrorMessage = styled(S.Text)`
  color: red;
  text-align: center;
  margin-top: 15px;
`

const AgeGate: React.SFC = () => {
  const [error, setError] = React.useState<boolean>(false)
  const [isOldEnough, setIsOldEnough] = React.useState(
    Cookies.get('old_enough')
  )

  const hide = () => {
    const body = document.querySelector('body')
    if (body) {
      body.style.overflow = 'auto'
    }
    setError(false)
    setIsOldEnough(true)
  }

  React.useEffect(() => {
    if (isOldEnough) {
      return
    }

    // Make body non-scrollable
    const body = document.querySelector('body')
    if (body) {
      body.style.overflow = 'hidden'
    }

    const options = {
      form: document.querySelector('form[name=agegate]'),
      name: 'old_enough_custom_name',
      countries: false,
      expiry: Infinity,
    }

    new AG(options, err => {
      if (err) {
        setError(true)
      } else {
        hide()
      }
    })
  }, [])

  if (isOldEnough) {
    return null
  }

  return (
    <Container>
      <Inner>
        <img src={logo} />
        <Form>
          <S.Text className="semiBold green" style={{ marginBottom: 30 }}>
            Hello, bonjour. You need to be at least 21 years old to shop on our site. Please enter your birthday before browsing.
          </S.Text>
          <form name="agegate">
            <S.Grid>
              <S.GridItem width="33.33%">
                <S.TextField
                  className="white"
                  type="number"
                  name="year"
                  placeholder="YYYY"
                  autoFocus
                />
              </S.GridItem>
              <S.GridItem width="33.33%">
                <S.TextField
                  className="white"
                  type="number"
                  name="month"
                  placeholder="MM"
                />
              </S.GridItem>
              <S.GridItem width="33.33%">
                <S.TextField
                  className="white"
                  type="number"
                  name="day"
                  placeholder="DD"
                />
              </S.GridItem>
            </S.Grid>
            <Submit className="darkGreen" type="submit">
              Enter
            </Submit>
            {error && (
              <ErrorMessage>Sorry, but you are not old enough (yet).</ErrorMessage>
            )}
          </form>
        </Form>
      </Inner>
    </Container>
  )
}

export default AgeGate
