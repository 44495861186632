import { graphql, Link, StaticQuery } from 'gatsby'
import * as React from 'react'
import * as C from '../components'
import * as S from '../styles'

const query = graphql`
  {
    allContentfulHomepageCollection {
      edges {
        node {
          product {
            id
            storefrontId
            variantId
            sku
            productInformation {
              ... on ContentfulProduct {
                slug
                sku
                name
                price
                regularPrice
                soldOut
                mainImage {
                  fluid(maxHeight: 450) {
                    src
                  }
                }
                vintage {
                  name
                }
              }
              ... on ContentfulBundle {
                slug
                sku
                name
                price
                regularPrice
                soldOut
                mainImage {
                  fluid(maxHeight: 450) {
                    src
                  }
                }
                vintage {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={query}
    render={({ allContentfulHomepageCollection }) => (
      <>
        <S.Grid>
          {allContentfulHomepageCollection.edges[0].node.product.map(p => {
            const { id, productInformation, variantId, sku } = p
            return (
              <S.GridItem
                key={id}
                width="30%"
                breakpoints={{
                  767: '50%',
                  479: '100%',
                }}>
                {sku.includes('capsule') && (
                  <C.BundleItem
                    name={productInformation.name}
                    variantId={variantId}
                    soldOut={productInformation.soldOut}
                    slug={productInformation.slug}
                    price={productInformation.price}
                    regularPrice={productInformation.regularPrice}
                    image={
                      productInformation.mainImage.fluid &&
                      productInformation.mainImage.fluid.src
                    }
                  />
                )}
                {!sku.includes('capsule') && (
                  <C.ProductItem
                    name={productInformation.name}
                    variantId={variantId}
                    soldOut={productInformation.soldOut}
                    slug={productInformation.slug}
                    price={productInformation.price}
                    regularPrice={productInformation.regularPrice}
                    image={
                      productInformation.mainImage.fluid &&
                      productInformation.mainImage.fluid.src
                    }
                  />
                )}
              </S.GridItem>
            )
          })}
        </S.Grid>
        <Link to="/shop">
          <S.Button>View all wines</S.Button>
        </Link>
      </>
    )}
  />
)
