import * as React from 'react'

import * as C from '../components'
import { About, Intro, Story, Wines, AgeGate } from '../home'

const IndexPage: React.SFC = () => (
  <C.App>
    <Intro />
    <Wines />
    <Story />
    <About />
    <C.JoinCommunity />
  </C.App>
)

export default IndexPage
